<template>
  <div class="HomeFeaturedTestimonials">
    <div class="HomeFeaturedTestimonials-background">
      <ai-icon
        class="HomeFeaturedTestimonials-background-asset"
        name="testimonials"
        :size="{ height: 580, width: 580 }" />
    </div>

    <ai-typo class="sr-only" as="h2" variant="overline-01" color="gold-900">
      {{ $t('ux.organisms.testimonials.title') }}
    </ai-typo>
    <ai-carousel-card-only
      id="HomeFeaturedTestimonials"
      v-model="currentStep"
      no-padding
      access-id="HomeFeaturedTestimonials"
      :items="items"
      :slide-width="1"
      :aria-label="
        $t('accessibility.description.HomeFeaturedTestimonialsCarousel')
      "
      :accessibility-label="$t('ux.organisms.testimonials.title')"
      :hide-navigation-button="true"
      :hide-progress-bar="true"
      :component="AiTestimonial">
      <template #footer>
        <div class="HomeFeaturedTestimonials-button">
          <ai-carousel-button
            :accessibility-label="$t('ux.organisms.testimonials.title')"
            @left-click="onPreviousSlide"
            @right-click="onNextSlide" />
        </div>
      </template>
    </ai-carousel-card-only>
  </div>
</template>

<script setup lang="ts">
import AiTestimonial from '~~/domains/ux/molecules/AiTestimonial/AiTestimonial.vue';
import type { TestimonialFragment } from '~~/domains/graphql';

type Props = {
  testimonials: TestimonialFragment[];
};

const props = defineProps<Props>();

const currentStep = ref<number>(1);

const items = computed(() =>
  props.testimonials.map(item => ({
    reviewText: item.reviewText,
    author: item.author,
    hotelName: item.hotel?.name,
    country: item.hotel?.address?.country,
  })),
);

const onPreviousSlide = () => {
  if (currentStep.value === 1) {
    currentStep.value = items.value.length;
  } else {
    currentStep.value--;
  }
};

const onNextSlide = () => {
  if (currentStep.value === items.value.length) {
    currentStep.value = 1;
  } else {
    currentStep.value++;
  }
};
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/constants';

.HomeFeaturedTestimonials {
  position: relative;
}

.HomeFeaturedTestimonials-background {
  position: absolute;
  z-index: -1;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HomeFeaturedTestimonials-background-asset {
  margin-left: constants.$margin-10;
  margin-bottom: constants.$margin-07;
}

#HomeFeaturedTestimonials {
  overflow: hidden;
}

.HomeFeaturedTestimonials-button {
  display: flex;
  justify-content: center;
}
</style>
