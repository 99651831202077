<!-- eslint-disable vue/no-parsing-error -->
<template>
  <ai-booking-bar
    ref="bookingBar"
    v-model:dates="dates"
    v-model:include-flights="includeFlights"
    v-model:rooms-and-guests="roomsAndGuests"
    :dates-errors="datesError"
    :manage-flights="isFlightHotelAvailable"
    :mobile-items="mobileItems"
    :mobile-titles="{
      origins: $t('homepage.hero.bar.mobile.origin.title'),
      destinations: $t('homepage.hero.bar.mobile.destination.title'),
    }"
    :rooms-and-guests-errors="childrenAgeMobileError"
    inverse
    slim
    @reset-all="reset">
    <template #input-before>
      <origin-autocomplete
        v-if="isFlightHotelAvailable && includeFlights"
        v-model="origin"
        :errors="originError"
        inverse />

      <destination-autocomplete
        v-model="destination"
        :errors="destinationError"
        inverse />
    </template>

    <template #action>
      <ai-button
        :label="$t('homepage.hero.bar.submit')"
        data-testid="homepageBookingSubmit"
        light
        @click="submit" />
    </template>

    <!-- Mobile -->
    <template #mobile-default-before="slotProps">
      <ai-field-input
        v-if="isFlightHotelAvailable && includeFlights"
        :errors="originError"
        :label="$t('homepage.hero.bar.mobile.origin.label')"
        :model-value="origin.text"
        :placeholder="$t('homepage.hero.bar.mobile.origin.placeholder')"
        class="HomeBookingBar-mobileInput"
        name="origin-mobile"
        readonly
        @click="slotProps.setPage('origins')"
        @keyup.enter="slotProps.setPage('origins')">
        <template #right>
          <ai-icon
            :size="18"
            class="AiBookingMobileForm-icon"
            name="location" />
        </template>
      </ai-field-input>

      <ai-field-input
        :errors="destinationError"
        :label="$t('homepage.hero.bar.mobile.destination.label')"
        :model-value="destination.text"
        :placeholder="$t('homepage.hero.bar.mobile.destination.placeholder')"
        class="HomeBookingBar-mobileInput"
        name="destination-mobile"
        readonly
        @click="slotProps.setPage('destinations')"
        @keyup.enter="slotProps.setPage('destinations')">
        <template #right>
          <ai-icon
            :size="18"
            class="AiBookingMobileForm-icon"
            name="location" />
        </template>
      </ai-field-input>
    </template>

    <!-- Mobile - Origin page -->
    <template #origins>
      <origin-autocomplete
        v-model="origin"
        :errors="originError"
        class="HomeBookingBar-mobilePage-input"
        hide-suggestions
        @origins-fetched="origins => (originSuggestions = origins)" />

      <origin-suggestion
        v-for="(suggestion, index) of originSuggestions"
        :id="suggestion.id"
        :key="`${suggestion.type}-${index}`"
        :country="suggestion.country"
        :full-name="suggestion.fullName"
        :name="suggestion.name"
        :type="suggestion.type"
        @click="setOriginSuggestion" />
    </template>

    <!-- Mobile - Destination page -->
    <template #destinations>
      <destination-autocomplete
        v-model="destination"
        class="HomeBookingBar-mobilePage-input"
        hide-suggestions
        @destinations-fetched="
          destinations => (destinationSuggestions = destinations)
        " />

      <destination-suggestion
        v-for="(suggestion, index) of destinationSuggestions"
        :key="`${suggestion.type}-${index}`"
        :city="suggestion.city"
        :country="suggestion.country"
        :hotel="suggestion.hotel"
        :type="suggestion.type"
        @click="setDestinationSuggestion" />
    </template>

    <template #mobile-submit>
      <ai-button
        :label="$t('homepage.hero.bar.mobile.submit')"
        @click="submit" />
    </template>
  </ai-booking-bar>
</template>

<script lang="ts" setup>
import { differenceInDays } from 'date-fns';

import {
  useFlightHotelAvailability,
  useFlightHotelUrlGenerator,
  useFlightHotelValidator,
} from '~/domains/feature';
import type {
  SearchDestination as GQLSearchDestination,
  SearchOrigin as GQLSearchOrigin,
} from '~/domains/graphql';
import { SearchDestinationResultType } from '~/domains/graphql';
import type { SearchDestination, SearchOrigin } from '~/domains/search';
import type { Props as DestinationSuggestionProps } from '~/domains/search/components/DestinationSuggestion.vue';
import type { Props as OriginSuggestionProps } from '~/domains/search/components/OriginSuggestion.vue';
import { MobileItem } from '~/domains/ux/organisms/AiBookingBar/AiBookingBar.vue';
import { FORMATS, formatDate } from '~/helpers';

const localePath = useLocalePath();
const { t } = useI18n();
const { isFlightHotelAvailable } = useFlightHotelAvailability();
const { generateUrl: generateExpediaUrl } = useFlightHotelUrlGenerator();
const {
  datesError,
  destinationError,
  originError,
  validateOrigin,
  softValidateDates,
  softValidateDestination,
} = useFlightHotelValidator();

const bookingBar = ref();

const origin = ref<SearchOrigin>({ text: '' });
const destination = ref<SearchDestination>({ text: '' });
const dates = ref<Date[]>([]);
const roomsAndGuests = ref<Array<{ adults: number; childrenAges: number[] }>>(
  [],
);
const includeFlights = ref(false);

const destinationSuggestions = ref<GQLSearchDestination[]>([]);

const originSuggestions = ref<GQLSearchOrigin[]>([]);

const mobileDestinationLabel = computed(() =>
  destination?.value.text === ''
    ? t('booking.bar.mobile.allDestinations')
    : destination?.value.text,
);

const mobileDestinationIsBold = computed(
  () =>
    mobileDestinationLabel?.value !== t('booking.bar.mobile.allDestinations'),
);

const mobileOriginLabel = computed(() =>
  origin?.value.text === ''
    ? t('booking.bar.mobile.selectOrigin')
    : origin?.value.text,
);

const mobileOriginIsBold = computed(
  () => mobileOriginLabel?.value !== t('booking.bar.mobile.selectOrigin'),
);

const mobileItems = computed<MobileItem[]>(() => {
  const destinationItem = {
    icon: 'location',
    key: 'all-destination',
    label: mobileDestinationLabel.value,
    position: 'before',
    bold: mobileDestinationIsBold.value,
  } as MobileItem;

  const originItem = {
    icon: 'location',
    key: 'origin',
    label: mobileOriginLabel.value,
    position: 'before',
    bold: mobileOriginIsBold.value,
  } as MobileItem;

  return includeFlights.value
    ? [originItem, destinationItem]
    : [destinationItem];
});

const childrenAgeMobileError = ref<
  { childrenAges: number[]; roomIndex: number }[]
>([]);

const reset = () => {
  origin.value.text = '';
  destination.value.text = '';
  childrenAgeMobileError.value = [];
  includeFlights.value = false;
};

const setDestinationSuggestion = (suggestion: DestinationSuggestionProps) => {
  const searchValue = {
    text: '',
    type: suggestion.type as SearchDestinationResultType,
  };

  switch (suggestion?.type) {
    case SearchDestinationResultType.Country:
      searchValue.text = suggestion.country as string;
      break;
    case SearchDestinationResultType.City:
      searchValue.text = suggestion.city as string;
      break;
    case SearchDestinationResultType.Hotel:
      searchValue.text = suggestion.hotel?.name as string;
      break;
  }

  destination.value = searchValue;

  if (typeof bookingBar.value.setPage === 'function') {
    bookingBar.value.setPage(undefined);
  }
};

const setOriginSuggestion = (suggestion: OriginSuggestionProps) => {
  origin.value = {
    text: suggestion.name,
    type: suggestion.type,
  };

  if (typeof bookingBar.value.setPage === 'function') {
    bookingBar.value.setPage(undefined);
  }
};

const assertChildrenAges = () => {
  childrenAgeMobileError.value = [];

  if (
    roomsAndGuests.value.some(room =>
      room.childrenAges.some(age => typeof age !== 'number'),
    )
  ) {
    for (
      let roomIndex = 0;
      roomIndex < roomsAndGuests.value.length;
      roomIndex++
    ) {
      const room = roomsAndGuests.value[roomIndex];
      const roomError = { roomIndex, childrenAges: [] } as {
        roomIndex: number;
        childrenAges: number[];
      };

      for (let i = 0; i < room.childrenAges.length; i++) {
        if (typeof room.childrenAges[i] === 'number') continue;

        roomError.childrenAges.push(i);
      }

      childrenAgeMobileError.value.push(roomError);
    }
  }
};

const submit = () => {
  assertChildrenAges();

  if (childrenAgeMobileError.value.length) return;

  const query: Record<string, string> = {};

  if (includeFlights.value) {
    validateOrigin(origin.value);

    if (originError.value) return;

    query.includeFlights = 'true';
    query.origin = JSON.stringify(origin.value);

    if (
      softValidateDates(dates.value) &&
      softValidateDestination(destination.value)
    ) {
      const url = generateExpediaUrl(origin.value, destination.value, {
        dateIn: formatDate(dates.value[0], FORMATS.apiFormat),
        nights: differenceInDays(dates.value[1], dates.value[0]),
        roomsAndGuests: roomsAndGuests.value,
      });

      window.open(url, '_self');

      return;
    }
  }

  if (dates.value.length) {
    query.dates = JSON.stringify(
      dates.value.map(date => formatDate(date, 'yyyy-MM-dd')),
    );
  }

  if (destination.value.text || destination.value.type) {
    query.destination = JSON.stringify(destination.value);
  }

  if (roomsAndGuests.value.length) {
    const hasEmptyChildren = roomsAndGuests.value.some(room =>
      room.childrenAges?.some?.(age => age < 0),
    );

    if (hasEmptyChildren) return;

    query.roomsAndGuests = JSON.stringify(roomsAndGuests.value);
  }

  navigateTo(localePath({ name: 'search', query }));
};

watch(origin, () => {
  originError.value = undefined;
});
watch(destination, () => {
  destinationError.value = undefined;
});
watch(dates, () => {
  datesError.value = undefined;
});
watch(includeFlights, isFlightIncluded => {
  if (!isFlightIncluded) {
    originError.value = undefined;
    destinationError.value = undefined;
    datesError.value = undefined;
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/styles/utilities/constants';

.HomeBookingBar-mobileInput {
  margin-bottom: constants.$margin-04;
}

.HomeBookingBar-mobilePage-input {
  margin-bottom: constants.$margin-02;
}
</style>
