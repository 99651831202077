<template>
  <div class="HomeHotelAndResorts">
    <ai-carousel-card-only
      id="HomeHotelAndResorts"
      v-model="currentStep"
      :accessibility-label="$t('homepage.HotelAndResorts.title')"
      :aria-label="$t('accessibility.description.carouselHotelAndRessorts')"
      :component="HomeHotelAndResortsItem"
      :gutter="gutter"
      :items="items"
      :slide-width="slideWidth"
      :title="$t('homepage.HotelAndResorts.title')"
      access-id="HomeHotelAndResortsCarousel"
      mobile-button />
  </div>
</template>

<script lang="ts" setup>
import HomeHotelAndResortsItem from '../HomeHotelAndResorts/HomeHotelAndResortsItem.vue';

type Props = {
  cards: Record<string, unknown>[];
};

const props = defineProps<Props>();

const { t } = useI18n();
const isSmallDevice = useMatchMediaQuery('tablet');

const gutter = computed(() => (isSmallDevice.value ? 16 : 32));
const slideWidth = computed(() => (isSmallDevice.value ? 0.9 : 0.333));

const currentStep = ref<number>(1);

const items = computed(() =>
  props.cards.map(item => ({
    brand: item.brand,
    city: item.city,
    country: item.country,
    ctaLabel: t('homepage.HotelAndResorts.ctaLabel'),
    label: item.label,
    lazyLoadImage: true,
    pictograms: item.pictograms,
    picture: item.picture,
    to: {
      name: 'hotel-hotelSlug',
      params: { hotelSlug: `${item.slug}` },
    },
  })),
);
</script>

<style lang="scss" scoped>
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/mq';

.HomeHotelAndResorts-button {
  margin-top: constants.$margin-03;

  @media (mq.$from-medium) {
    display: none;
  }
}
</style>
